
import React, { useState } from 'react';
import axios from 'axios';
import '../css/AnnuleeConfirmModal.css'

const InitializeConfirmModal = ({ actionBanque,typeDemand,numeroDemand,getCommandChild,user,hash,initializeConfirmModal, setInitializeConfirmModal }) => {

  const validateSubmit = async () => {
    await axios
      .post(`https://diardzair.com.dz/api/taksit/rest/set/etatDossierBanque`,
        JSON.stringify({ username: user.user, orderId: user.orderId, hash: hash, etat: actionBanque === "14" ? "initialiser" : "initialisergarantie", motifRefus: "" }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
      .then(res=>{  
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

      await axios.post('https://diardzairstocks.store/api/lotproduit/cancelDemand',
          JSON.stringify({numero: numeroDemand, type: typeDemand, username: 'az789uitd', password: 'iop4587pmodter7'}),
            {
              headers: {
                'Content-Type': 'application/json',
                }
            }
        ).then((res) => {
            setInitializeConfirmModal(false);
            getCommandChild();
          })
          .catch((err) => {
            console.log(err);
          });

  }

  return (
    <div className={initializeConfirmModal ? 'validate-modal display-modal' : 'validate-modal'}>
      <div className='validate-back' onClick={() => setInitializeConfirmModal(false)}></div>
      <div className='validate-box'>
        <div className='validate-title'>
          <span>Message de Confirmation</span>
        </div>
        <div className='validate-form'>
          <p style={{ "lineHeight":0 }}>Cette action supprimera LS et LD de cette commande</p>
          <p style={{ "lineHeight":0 }}>Etes-vous sûr de vouloir Initialiser :</p>
          <p> {actionBanque === "14" ? "initialiser" : "initialisergarantie"}</p>
          <div className='validate-submit'>
            <span onClick={validateSubmit}>
              Oui
              <svg width="34px" heigh="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.06 40.25">
                <defs><style>{`.cls-modal-0{fill:#fff;}`}</style></defs>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <g id="Layer_2-2" data-name="Layer 2">
                      <g id="Layer_1-2-2" data-name="Layer 1-2">
                        <g id="Layer_2-2-2" data-name="Layer 2-2">
                          <g id="Layer_1-2-2-2" data-name="Layer 1-2-2"><path className="cls-modal-0" d="M61,5.42a5.47,5.47,0,0,1-2.25,4.44L16.55,40.21a.13.13,0,0,1-.18,0h0l-.21-.32h0L1,18.11a5.49,5.49,0,0,1,1.28-7.57,5.27,5.27,0,0,1,7.36,1.19l.07.11L19,25.2,52.62,1A5.27,5.27,0,0,1,60,2.2a.86.86,0,0,1,.07.1,5.37,5.37,0,0,1,1,3.12Z" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InitializeConfirmModal


